import { FlexProps, Flex, Text } from '@chakra-ui/core'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'
import Swipe from 'react-easy-swipe'

import NextButton from '../NextButton'
import PrevButton from '../PrevButton'
import CarouselDots from './CarouselDots'

const fadeIn = keyframes`
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
`

interface BaseCarouselState {
  currentIndex: number
}

export interface BaseCarouselProps extends FlexProps {
  children: React.ReactElement[]
  thresholdX: number
  dotPosition: 'top' | 'bottom'
  showArrows: boolean
  showDots: boolean
  enableScroll: boolean
  startingIndex: number
  isGallery: boolean
  imagePaths: string[]
  dotsBgColor?: string
  showControlsAbove: boolean
  showProgress: boolean
  fullWidth: boolean
  absoluteDots?: boolean
  isRTL?: boolean
  arrowPos?: number | Array<number | string | null>
  dotBottomPos?: FlexProps['bottom']
  dotsLeftPos?: FlexProps['left']
  progressBottomPos?: FlexProps['bottom']
  progressRightPos?: FlexProps['right']
  roundedArrows?: boolean
  arrowButtonSize?: 'sm' | 'md'
  arrowButtonColor?: string
  arrowButtonBg?: string
}

class BaseCarousel extends React.Component<
  BaseCarouselProps,
  BaseCarouselState
> {
  static defaultProps = {
    thresholdX: 50,
    dotPosition: 'bottom',
    showArrows: true,
    showDots: true,
    enableScroll: true,
    startingIndex: 0,
    isGallery: false,
    imagePaths: [],
    dotsBgColor: 'black',
    showControlsAbove: false,
    showProgress: false,
    fullWidth: false,
  }

  isSwiping = false
  movedX = 0

  constructor(props: BaseCarouselProps) {
    super(props)
    const { startingIndex } = props
    this.state = {
      currentIndex: startingIndex,
    }
    this.isSwiping = false
    this.movedX = 0
  }

  onSwipeStart = () => {
    this.isSwiping = true
  }

  onSwipeMove = (position: { x: number; y: number }) => {
    this.movedX = position.x
  }

  onSwipeEnd = () => {
    this.isSwiping = false
    const movedX = this.movedX
    this.movedX = 0

    if (Math.abs(movedX) < this.props.thresholdX) {
      return
    }

    movedX < 0 ? this.moveRight() : this.moveLeft()
  }

  moveLeft = () => {
    const { children } = this.props
    const { currentIndex } = this.state
    if (currentIndex === 0) {
      // go back to 0
      this.setState({
        currentIndex: children.length - 1,
      })
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      })
    }
  }

  moveRight = () => {
    const { children } = this.props
    const { currentIndex } = this.state

    if (currentIndex === children.length - 1) {
      // go back to 0
      this.setState({
        currentIndex: 0,
      })
    } else {
      // go back to 0
      this.setState({
        currentIndex: currentIndex + 1,
      })
    }
  }

  prevSlide = () => {
    if (this.props.isRTL) {
      this.moveRight()
    } else {
      this.moveLeft()
    }
  }

  nextSlide = () => {
    if (this.props.isRTL) {
      this.moveLeft()
    } else {
      this.moveRight()
    }
  }

  render() {
    const {
      children,
      enableScroll,
      showArrows,
      showDots,
      dotPosition,
      isGallery,
      imagePaths,
      // disable lint, these are used to remove from ...rest
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      thresholdX,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      startingIndex,
      dotsBgColor,
      showControlsAbove,
      showProgress,
      fullWidth,
      absoluteDots,
      isRTL,
      arrowPos,
      dotBottomPos,
      progressBottomPos = 6,
      progressRightPos = 6,
      roundedArrows = false,
      arrowButtonColor,
      arrowButtonSize,
      arrowButtonBg,
      dotsLeftPos,
      ...rest
    } = this.props

    const { currentIndex } = this.state
    const swipeProps = {
      onSwipeStart: this.onSwipeStart,
      onSwipeMove: this.onSwipeMove,
      onSwipeEnd: this.onSwipeEnd,
      style: { height: '100%' },
    }
    return (
      <Swipe
        {...(enableScroll && swipeProps)}
        style={{ height: '100%', width: fullWidth ? '100%' : 'auto' }}
      >
        <Flex direction="column" align="stretch" position="relative" {...rest}>
          {showDots && dotPosition === 'top' && (
            <CarouselDots
              alignSelf="center"
              mb={3}
              isAboveSlide={showControlsAbove}
              total={children.length}
              currentIndex={currentIndex}
              onDotClick={(ind: number) => this.setState({ currentIndex: ind })}
              isGallery={isGallery}
              imagePaths={isGallery ? imagePaths : []}
              dotBottomPos={dotBottomPos}
              bg={dotsBgColor}
              left={dotsLeftPos}
            />
          )}
          <Flex
            flex={1}
            className="carousel-content"
            direction="row"
            align="stretch"
            justify="stretch"
          >
            {showArrows && (
              <PrevButton
                onClick={this.prevSlide}
                showControlsAbove={showControlsAbove}
                left={arrowPos}
                rounded={roundedArrows}
                size={arrowButtonSize}
                bgColor={arrowButtonBg}
                arrowColor={arrowButtonColor}
              />
            )}
            {React.Children.map(children, (child, ind) => {
              if (ind === currentIndex) {
                return child
              } else {
                return
              }
            })}
            {showArrows && (
              <NextButton
                onClick={this.nextSlide}
                showControlsAbove={showControlsAbove}
                right={arrowPos}
                rounded={roundedArrows}
                size={arrowButtonSize}
                bgColor={arrowButtonBg}
                arrowColor={arrowButtonColor}
              />
            )}
          </Flex>
          {showDots && dotPosition === 'bottom' && (
            <CarouselDots
              mt={isGallery ? [3, 5, 5] : [3]}
              alignSelf="center"
              total={children.length}
              absoluteDots={absoluteDots}
              currentIndex={currentIndex}
              onDotClick={(ind) => this.setState({ currentIndex: ind })}
              isGallery={isGallery}
              imagePaths={isGallery ? imagePaths : []}
              dotBottomPos={dotBottomPos}
              bg={dotsBgColor}
              left={dotsLeftPos}
            />
          )}
          {showProgress && (
            <Text
              size="sm"
              position="absolute"
              bottom={progressBottomPos}
              right={progressRightPos}
            >
              {currentIndex + 1}/{children.length}
            </Text>
          )}
        </Flex>
      </Swipe>
    )
  }
}

export const Carousel = styled(BaseCarousel)`
  & > .carousel-content .hidden {
    display: none !important;
  }

  & > .carousel-content .fade-in {
    animation: ${fadeIn} 1.5s;
  }
`

export default Carousel
