import { Text, Heading, Flex } from '@chakra-ui/core'
import React from 'react'

import { CustomerReview } from '../../../freeTrial/types'
import { useRTL } from '../../../i18n/hooks'

const CustomerReviewComponent: React.FC<CustomerReview> = ({
  name,
  header,
  text,
  fontWeight = 'bold',
}) => {
  const { isRTL } = useRTL()

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      className="fade-in"
    >
      <Heading size="md">{header}</Heading>

      <Text
        size="lg"
        py={[3, 3, 4]}
        maxWidth="690px"
        textAlign={isRTL ? 'right' : 'center'}
        minHeight="150px"
        fontWeight="bold"
      >
        {text}
      </Text>

      <Text size="md" mb={3} mt={6} fontWeight="bold">
        {'- ' + name.toUpperCase()}
      </Text>
    </Flex>
  )
}

export default CustomerReviewComponent
