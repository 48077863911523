import { Text, Flex, Box, Image } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CustomerTestimonial } from '../../../freeTrial/types'

type CustomerTestimonialComponentProps = {
  testimonial: CustomerTestimonial
}

const CustomerTestimonialComponent: React.FC<CustomerTestimonialComponentProps> = ({
  testimonial: { name, age, skinType, productUse, image, comment },
}) => {
  const { t } = useTranslation()
  return (
    <Box
      w="100%"
      maxW="540px"
      minH={['300px', '335px', '335px']}
      maxH="360px"
      m="0 auto"
      p={[4, 6, 8]}
      bgColor="white"
    >
      <Flex align="flex-start">
        <Image
          src={`/img/testimonials/${image}`}
          w={['50px', null, '70px']}
          h={['50px', null, '70px']}
          borderRadius="round"
          mr={[4, null, 6]}
        />
        <Box w="100%">
          <Text as="h2" size="lg" fontWeight="bold">
            {name}
          </Text>
          <Flex align="center">
            <Text
              as="h3"
              size="sm"
              textTransform="uppercase"
              fontWeight="bold"
              mr={1}
            >
              {t('testimonials:age', 'age')} : 
            </Text>
            <Text mr={2}>{age}</Text>
            <Text
              as="h3"
              size="sm"
              textTransform="uppercase"
              fontWeight="bold"
              mr={1}
            >
              {t('testimonials:askin-type', 'skin type')} : 
            </Text>
            <Text>{skinType}</Text>
          </Flex>
          <Flex align="center" flexWrap="nowrap">
            <Text
              as="h3"
              size="sm"
              textTransform="uppercase"
              fontWeight="bold"
              mr={2}
            >
              {t('testimonials:product-use', 'product use')} : 
            </Text>
            <Text>{productUse}</Text>
          </Flex>
        </Box>
      </Flex>
      <Box
        w="100%"
        maxH="145px"
        mt={8}
        overflowY="auto"
        overflowX="hidden"
      >
        <Text>{comment}</Text>
      </Box>
    </Box>
  )
}

export default CustomerTestimonialComponent
