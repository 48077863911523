import { TFunction } from 'i18next'

import {
  CustomerReview,
  CustomerTestimonial,
} from '../types'

export const getCustomerReviews = (t: TFunction): CustomerReview[] => [
  {
    name: t('testimonial:are.name','Arek'),
    header: t('freeTrial:just-love-this-product', 'I just love this product'),
    text: t(
      'freeTrial:just-love-this-product-description',
      'I stumbled upon it on Instagram and decided to give it a try. I have to admit my skin really improved. It looks much healthier now. I started with a moisturizer and now use all the products. Highly recommended!!!'
    ),
  },
  {
    name: t('testimonial:jim-s.name','Jim S.'),
    header: t(
      'freeTrial:my-skin-has-never-been-healthier',
      'My skin has never been healthier'
    ),
    text: t(
      'freeTrial:my-skin-has-never-been-healthier-desc',
      'My skin has been healthier and complexion clearer since beginning with Lumin 3 months ago. I tried their initial month trial, and was impressed (specifically with the moisturizer). Highly recommended!'
    ),
  },
  {
    name: t('testimonial:erich-c.name','Erich C.'),
    header: t(
      'freeTrial:high-quality-and-convenient',
      'High Quality and Convenient'
    ),
    text: t(
      'freeTrial:high-quality-and-convenient-desc',
      "I've been using this product for months now. Starting from their free trial. Between the value and quality, it is a perfect balance. The convenience of having the product shipped directly to your door without having to think about it is amazing. It may seem pricey for some but is certainly worth every penny."
    ),
  },
  {
    name: t('testimonial:eric-g.name','Eric G.'),
    header: t('freeTrial:see-ya-dark-circles', 'See Ya, Dark Circles'),
    text: t(
      'freeTrial:see-ya-dark-circles-desc',
      'I absolutely love the DARK CIRCLE DEFENSE cream! I have a subscription so I never run out of the product. I was looking and looking for a product to help with darkness under the eye. This cream has really improved my under eye area. Also, side note - their customer service is great, I recently was sent the wrong product and they quickly responded and sent the correct item, I really appreciated their prompt service'
    ),
  },
  {
    name: t('testimonial:brad-t.name','Brad T.'),
    header: t(
      'freeTrial:clear-skin-and-compliments',
      'Clear Skin & Compliments'
    ),
    text: t(
      'freeTrial:clear-skin-and-compliments-desc',
      'My skin felt instantly cleaner! I’ve had a number of people mention how much better my skin looks and how I look more energized!'
    ),
  },
]

export const getCustomerTestimonials = (t: TFunction): CustomerTestimonial[] => [
  {
    name: t('testimonial:wil-c.name','Wil C.'),
    age: t('testimonial:age-range-35-45', '35-45'),
    skinType: t('testimonial:skin-type-oily', 'Oily'),
    productUse: t('testimonial:product-use-classic', 'Classic Maintenance'),
    image: 'wil_c.jpeg',
    comment: t(
      'testimonial:pleased-with-the-results',
      "I have been using Lumin's products over 6 months and have been very pleased with the results. I have tried other moisturizers in the past and have always broken out. I just about gave up on the idea of moisturizing before bed when I decided to give Lumin a try. Not only have I not broken out for daily use, but my overall skin tone has improved. The 'Moisturizing Balm', 'Exfoliating Rub', and ' Charcoal Cleanser' are now part of my routine. As someone who has always had less than perfect skin, I have a little extra confidence when I go about my life. Thanks Lumin!"
    ),
  },
  {
    name: t('testimonial:tom-h.name','Tom H.'),
    age: t('testimonial:age-range-13-24', '13-24'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: t('testimonial:product-use-dark-circle', 'Dark Circle Defense'),
    image: 'tom_h.jpeg',
    comment: t(
      'testimonial:favourite-skincare-brand',
      "LUMIN is my new favourite skincare brand, they use such sleek and crisp designs and the products are of the highest quality!. I’ve always been jealous of the wonderfully lavish facial routines used by my female friends and now I finally have them asking me to use my products! Thanks LUMIN you really have lit up my life"),
  },
  {
    name: t('testimonial:sam-w.name','Sam W.'),
    age: t('testimonial:age-range-13-24', '13-24'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: t('testimonial:product-use-shampoo', 'Shampoo'),
    image: 'sam_w.jpeg',
    comment: t(
      'testimonial:keep-breakouts-to-minimum',
      "I’ve always had trouble with my skin. I suffered from recurring acne and constant dry skin that stayed with me into adulthood. I’ve been through a variety of men’s skincare products from Brickell to Jack Black to specific acne washes. Each of them either upset my skin or didn’t treat my acne. Lumin was able to keep breakouts to a minimum as well as keep my skin hydrated. Would recommend to anyone trying to find a better skincare routine as well as anyone starting out."
    ),
  },
  {
    name: t('testimonial:povilas-a.name','Povilas A.'),
    age: t('testimonial:age-range-25-34', '25-34'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: t('testimonial:product-use-classic', 'Classic Maintenance'),
    image: 'povilas_a.jpeg',
    comment: t(
      'testimonial:perfect-price-size-quality',
      "Perfect price, size and quality balance. I always had issues with my skin, just because of buying different products for skincare every time. But now I’m very satisfied with my Lumin deal. Every 2 months I receive 3 products and I don’t need to think of, where and when I should go and get a new one. The products comes in a small ball bag, that I often love to take it with me to the gym in the morning. My face really got better, I don’t have any dry spots or irritations any more, face looks healthy. I love the relaxing and fresh feeling after using it every time. I am very excited to try more of your products and this is one of the best solutions in these hard days, when there is no time to be out in the shops. Keep improving, cheers!"),
  },
  {
    name: t('testimonial:paul-h.name','Paul H.'),
    age: t('testimonial:age-range-25-34', '25-34'),
    skinType: t('testimonial:skin-type-oily', 'Oily'),
    productUse: t('testimonial:product-use-dark-circle', 'Dark Circle Defense'),
    image: 'paul_h.jpeg',
    comment: t(
      'testimonial:face-feels-great',
      "I started using Lumin in the winter and it's been a routine that I love. Normally my face is oily and after the charcoal cleanser my face feels great. I get comments all the time that my face looks great and people asking what I do to keep my complexion clean. I highly recommend this product!"
    ),
  },
  {
    name: t('testimonial:matthew-k.name','Matthew K.'),
    age: t('testimonial:age-range-35-45', '35-45'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: 'Age Management Set',
    image: 'matthew_k.jpeg',
    comment: t(
      'testimonial:overall-complexion-more-even',
      "I have been using Lumin products for a few months, the moisturizing balm and dark circle defense. My skin has a new sheen and elasticity. I have also noticed my overall complexion is more even and smooth. The additional travel products are an amazing addition to the prescribed delivery."
    ),
  },
  {
    name: t('testimonial:joe-c.name','Joe C.'),
    age: t('testimonial:age-range-25-34', '25-34'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: t('testimonial:product-use-dark-circle', 'Dark Circle Defense'),
    image: 'joe_c.jpeg',
    comment: t(
      'testimonial:impressed-with-results',
      "I have been Lumin Skincare for over two months now. I am so impressed with the results I have been seeing. I have had lots of comments from people around me saying how great my skin is looking. I love the products I am using. Charcoal cleanser, moisturising balm and exfoliator. Each product is beautiful in consistency and fantastic quality. I have loved my experience with the website, subscription and email services I have received. Overall, a fantastic experience."
    ),
  },
  {
    name: t('testimonial:jimmy-w.name','Jimmy W.'),
    age: t('testimonial:age-range-25-34', '25-34'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: t('testimonial:product-use-classic', 'Classic Maintenance'),
    image: 'jimmy_w.jpeg',
    comment: t(
      'testimonial:stick-to-lumin-for-long-time',
      "I've been looking for a skin care system that I could stick to and that is simple for a long time. Lumin brings both of those together. They have different products that are part of a 3 step process which is easy to follow. I like that you can customize and change up what is part of your subscription - I traded the balm for the dark circle cream and can't decide what I like better. Also, Lumin lets you delay your subscription by weeks or months which is nice because after getting 2 months supply, I don't need any refills for probably 3 months. Oh, and the stuff works - I'm a sucker for charcoal products and the face wash is one of my favorites. The exfoliating rub is great at removing dirt and grime from your face. And the moisturizing balm really makes my skin soft, clear, and hydrates."
    ),
  },
  {
    name: t('testimonial:camron-k.name','Camron K.'),
    age: t('testimonial:age-range-35-45', '35-45'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: t('testimonial:product-use-dark-circle', 'Dark Circle Defense'),
    image: 'camron_k.jpeg',
    comment: t(
      'testimonial:classic-maintenance-collection-is-fantastic',
      "I really love Lumin products. The Classic Maintenance Collection is fantastic, it helped repair my skin and it feels amazing. The exfoliator helps deep clean and clear out my pores which leaves my face fresh, the charcoal cleanser- I can feel it working to really get a good clean and the moisturizer is awesome - I feel refreshed and my skin just feels better with it! I can't say enough good things about Lumin."
    ),
  },
  {
    name: t('testimonial:alec-s.name','Alec S.'),
    age: t('testimonial:age-range-25-34', '25-34'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: t('testimonial:product-use-classic', 'Classic Maintenance'),
    image: 'alec_s.jpeg',
    comment: t(
      'testimonial:great-customer-service',
      "Great product, great customer service, GREAT SKIN!!!!!. I use Lumin face wash and moisturizer every day, morning and night, and have been for close to a year now. It’s super simple and easy to stick to; even on those nights when you just want to go to bed. When I first started, I didn’t know what to expect but I decided to try it for at least a month and I noticed a drastic difference. The pores on my nose were clearer, my skin didn’t feel dry and there was almost a glow that I had never seen before; even my wife agreed and she’s usually extremely skeptical!. I usually have trouble with moisturizers not working with my skin but after about two weeks the small stubborn pimples that would normally pop up were gone and my skin felt extremely hydrated. Early on customer service contacted me to ask about my experience, After asking a few questions they were quick to respond and extremely helpful; a subtle reminder that this isn’t some “fly-by” skin care company and that they really care about their product and their brand! Needless to say, I’m extremely happy with the product, the service and most of all my skin! It’s starting to become an essential routine before bedtime that I can’t go without."),
  },
  {
    name: t('testimonial:alfonso-s.name','Alfonso S.'),
    age: t('testimonial:age-range-25-34', '25-34'),
    skinType: t('testimonial:skin-type-combination', 'Combination'),
    productUse: t('testimonial:product-use-dark-circle', 'Dark Circle Defense'),
    image: 'afonso_s.jpeg',
    comment: t(
      'testimonial:affordable-face-products',
      "I have been looking for affordable face products for quite some time, and this is exactly what I wanted: mid-range price, with terrific quality! It cleans, refreshes and restores the skin. Before going to bed it really is a pleasure moment to use Lumin products. I have been using the correction trio, and the truth is that I have much fewer blemishes and pimples. The packaging is nice, and the customer service is kind and helpful."
    ),
  },
]
