import { Flex, Box, Image } from '@chakra-ui/core'
import React from 'react'

const CarouselDots = ({
  total,
  currentIndex,
  onDotClick,
  isGallery,
  imagePaths,
  bg = 'black',
  absoluteDots = false,
  dotBottomPos,
  ...rest
}) => {
  const dots: React.ReactNode[] = []

  if (!isGallery) {
    for (let i = 0; i < total; i++) {
      dots.push(
        <Box
          as="span"
          key={i}
          cursor="pointer"
          borderRadius="round"
          borderWidth="0.5px"
          boxSizing="border-box"
          w="12px"
          h="12px"
          ml="5px"
          bg={i === currentIndex ? { bg } : 'none'}
          onClick={() => onDotClick(i)}
        />
      )
    }
  } else {
    for (let i = 0; i < total; i++) {
      dots.push(
        <Flex
          as="span"
          key={i}
          cursor="pointer"
          borderRadius="round"
          borderWidth="1px"
          boxSizing="border-box"
          p="5px"
          w="40px"
          h="40px"
          ml="5px"
          borderColor={i === currentIndex ? 'black' : 'transparent'}
          bg="offwhite3"
          onClick={() => onDotClick(i)}
        >
          <Image src={imagePaths[i]} objectFit="contain" borderRadius="round" />
        </Flex>
      )
    }
  }
  return (
    <Flex
      direction="row"
      bottom={dotBottomPos || 6}
      zIndex={1}
      position={absoluteDots ? 'absolute' : 'static'}
      justify="space-around"
      align="center"
      {...rest}
    >
      {dots}
    </Flex>
  )
}

export default CarouselDots
