import { Flex, Text, Button } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import staticRoutes from '../../../static/routes'
import ShopifyNextLink from '../ShopifyNextLink'
import CustomerReviews from './CustomerReviews'
import CustomerTestimonials from './CustomerTestimonials'

interface CustomerReviewsSectionProps {
  color?: string
  bgColor?: string
  dotsBgColor?: string
  title?: string
  showReviewsButton?: boolean
  variantType?: 'review' | 'testimonial' | null
}

type CustomerReviewsContainerProps = {
  color?: string
  bgColor?: string
  dotsBgColor?: string
  title?: string
  showReviewsButton?: boolean
}

const CustomerReviewsContainer: React.FC<CustomerReviewsContainerProps> = ({
  children,
  showReviewsButton = false,
  color = 'black',
  bgColor = 'offwhite',
  title,
}) => {
  const { t } = useTranslation()

  return (
    <Flex
      color={color}
      direction="column"
      align="center"
      justify="center"
      minHeight="400px"
      backgroundColor={bgColor}
      px={[4, 0, 0]}
      py={[10, 12, 16]}
    >
      <Text
        size="lg"
        fontWeight={color === 'black' ? 'bold' : ['bold', 'bold', 'bold']}
        textAlign="center"
        marginBottom={8}
        marginTop={2}
      >
        {title ||
          t('home:what-customeres-are-saying', 'What customers are saying')}
      </Text>
      {children}
      {showReviewsButton && (
        <ShopifyNextLink href={staticRoutes.reviews()} isInternal={false}>
          <Button variant="outline-white" size="lg" mt={10} whiteSpace="normal">
            <Text size="md" fontWeight="bold">
              {t('home:read-all-reviews', 'Read All Reviews')}
            </Text>
          </Button>
        </ShopifyNextLink>
      )}
    </Flex>
  )
}

const CustomerReviewsSection: React.FC<CustomerReviewsSectionProps> = ({
  variantType = 'review',
  dotsBgColor,
  color,
  showReviewsButton,
  title,
  bgColor,
}) => {
  if (variantType === 'testimonial') {
    return (
      <CustomerReviewsContainer
        showReviewsButton={showReviewsButton}
        color={color}
        bgColor={bgColor}
        title={title}
      >
        <CustomerTestimonials />
      </CustomerReviewsContainer>
    )
  } else if (variantType === 'review') {
    return (
      <CustomerReviewsContainer
        showReviewsButton={showReviewsButton}
        color={color}
        bgColor={bgColor}
        title={title}
      >
        <CustomerReviews dotsBgColor={dotsBgColor} color={color} />
      </CustomerReviewsContainer>
    )
  }
  return null
}

export default CustomerReviewsSection
