import React from 'react'
import { useTranslation } from 'react-i18next'

import { getCustomerReviews } from '../../../freeTrial/constants/customerReviews'
import Carousel from '../Carousel'
import CustomerReviewComponent from './CustomerReviewComponent'

export type CustomerReviewsProps = {
  dotsBgColor?: string
  color?: string
}

const CustomerReviews: React.FC<CustomerReviewsProps> = ({
  color = 'black',
  dotsBgColor = 'black',
}) => {
  const { t } = useTranslation()

  return (
    <Carousel showArrows={false} dotsBgColor={dotsBgColor}>
      {getCustomerReviews(t).map((custReview, index) => {
        return (
          <CustomerReviewComponent
            key={index}
            name={custReview.name}
            text={custReview.text}
            header={custReview.header}
            fontWeight={color === 'black' ? 'bold' : 'normal'}
          />
        )
      })}
    </Carousel>
  )
}

export default CustomerReviews
